<template>
  <section class="showcase bg-bottom relative min-h-screen w-full bg-cover md:bg-center bg-no-repeat bg-fixed flex items-center" id="showcase">
    <div class="container p-4 lg:p-8 mx-auto">
      <div class="grid grid-cols-1 lg:grid-cols-2">
        <div class="text-center flex justify-center items-start flex-col">
          <vue-typed-js
            :strings="['web development solutions.', 'software solutions.', 'bitcoin and liquid network solutions.', 'lightning network development services.']"
            :loop="true"
            :startDelay="1000"
            :typeSpeed="50"
            :backSpeed="25"
          >
            <h1 class="text-lg text-gray-100 mb-4">We offer <span class="typing text-yellow-300 yellow-gradient"></span></h1>
          </vue-typed-js>

          <h1
            class="title relative uppercase text-left text-xl md:text-3xl xl:text-4xl font-bold text-gray-50 lg:leading-tight xl:leading-normal"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Experts in web development & <br class="hidden sm:inline-block" />
            software solutions
          </h1>
          <p class="text-white text-left mt-4 leading-loose" data-aos="fade-up" data-aos-delay="700" data-aos-duration="2300">
            We are passionate about our work, and we are committed to providing the best possible high-quality services to our clients.
          </p>
        </div>
        <div data-aos="zoom-in" data-aos-delay="600" data-aos-duration="1000">
          <img src="../assets/img/hero/hero-techs.svg" class="md:w-2/4 lg:w-full" alt="Hero Illustration" v-tilt="{ glare: true, 'max-glare': 0.7 }" />
        </div>
      </div>
    </div>

    <a href="#webdev" class="scroll-down" v-smooth-scroll>
      <div class="mouse">
        <span></span>
      </div>
      <div class="arrow">
        <span></span>
      </div>
    </a>
  </section>
</template>

<script>
export default {
  name: "Hero"
};
</script>

<style lang="scss" scoped>
.showcase {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../assets/img/hero/hero-blue.png");
}

.center {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66%;
  h1 {
    line-height: 1.5;
    letter-spacing: 3px;
  }
}

.yellow-gradient {
  background: linear-gradient(to right, #f9d5230d 0%, #f9d5232f 50%, #f9d52300 100%);
}

@media screen and (max-width: 992px) {
  .title {
    &:before {
      top: -83%;
    }
  }
}


/* --------------------------------

arrow scroll down

-------------------------------- */

.scroll-down {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .mouse {
    width: 1.5rem;
    height: 2.5rem;
    border: 2px solid #d8d7d7c4;
    border-radius: 2rem;
    margin-bottom: 0.5rem;
    span {
      width: 0.5rem;
      height: 0.5rem;
      background: linear-gradient(to bottom, rgba(243, 242, 242, 0.733) 0%, rgba(0, 0, 0, 0.15) 100%),
        radial-gradient(at top center, rgba(255, 255, 255, 0.856) 0%, rgba(224, 223, 223, 0.884) 120%) #e7e6e6a4;
      background-blend-mode: multiply, multiply;
      display: block;
      border-radius: 50%;
      margin: 1rem auto;
      animation: scrolldown 2s linear infinite;
    }
  }
  .arrow {
    span {
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      border: 2px solid transparent;
      border-right-color: rgb(236, 236, 236);
      border-bottom-color: rgb(216, 209, 209);
      transform: rotate(45deg);
      animation: arrowdown 1s alternate infinite;
      margin-top: -0.5rem;
    }
  }
}

@keyframes scrolldown {
  0% {
    opacity: 0;
    transform: translateY(-0.5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0.5rem);
  }
}

@keyframes arrowdown {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}
</style>
