<template>
  <section class="container mx-auto relative">
    <nav class="absolute top-0 left-0 w-full mt-4" role="navigation">
      <div class="container mx-auto p-0 lg:py-4 lg:px-8 flex flex-wrap items-center lg:flex-no-wrap" :class="{ 'bg-primary': openNav }">
        <a href="#" class="mr-4 sm:mr-8 lg:mr-12 xl:mr-16 z-50"> <img src="../assets/img/meliorem-logo.svg" alt=""/></a>

        <div class="ml-auto px-4 lg:hidden z-50 nav-toggler" :class="{ active: openNav }" @click="toggleNav" id="navToggle">
          <span></span>
        </div>

        <div
          class="absolute tansition -left-full lg:left-auto duration-500 ease-in-out lg:relative -top-4 min-h-screen lg:min-h-full w-8/12 lg:w-auto lg:flex-grow lg:flex lg:items-center bg-sky lg:bg-transparent z-50 uppercase lg:capitalize showcase"
          id="navContent"
          :class="{ activeSideNav: openNav }"
        >
          <ul class="flex flex-col mt-4 lg:flex-row lg:items-center lg:mx-0 lg:ml-auto lg:mt-0 lg:pt-0 lg:border-0 space-y-4 md:space-y-0">
            <li>
              <img src="../assets/img/meliorem-logo.svg" class="md:hidden mb-4" alt="Meliorem Logo" />
            </li>
            <li>
              <a class="nav-link" href="#">Home</a>
            </li>
            <li>
              <a class="nav-link" href="#bitcoin" v-smooth-scroll>Bitcoin</a>
            </li>
            <li>
              <a class="nav-link" href="#services" v-smooth-scroll>Services</a>
            </li>
            <li>
              <a class="nav-link" href="#technologies" v-smooth-scroll>Technologies</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      openNav: false
    };
  },
  methods: {
    toggleNav() {
      this.openNav = !this.openNav;
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin underline {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  margin: 0 auto;
  border-bottom: 2px solid #f3f3f3;
}

.nav-link {
  @apply block px-4 py-3 sm:py-1 sm:p-2 md:px-4 text-gray-200 hover:text-white relative;
}

.activeSideNav {
  left: 0px;
}

@media (min-width: 1024px) {
  .lg\:bg-transparent {
    background-color: transparent !important;
  }
}

.nav-toggler {
  position: absolute;
  right: 0.75rem;
  top: 8px;
  cursor: pointer;
  padding: 17px 39px 17px 5px;
  border-radius: 3px;
  span,
  span:before,
  span:after {
    cursor: pointer;
    border-radius: 1rem;
    height: 4px;
    width: 35px !important;
    background: #ffffff;
    position: absolute;
    right: 0;
    display: block;
    content: "";
    transition: all 300ms ease-in-out;
  }
  span:before {
    top: -10px;
    width: 31px !important;
  }
  span:after {
    bottom: -10px;
    width: 28px !important;
  }
  &.active {
    box-shadow: none;
    transition: all 0.5s ease-in-out;
    span {
      width: 0px !important;
    }
    span:before {
      top: -5px !important;
      right: -8px;
      transform: rotate(-45deg) translate(-7px, 0px);
      width: 31px !important;
    }
    span:after {
      transform: rotate(45deg) translate(-7px, 0px);
      width: 31px !important;
      top: 5px;
      right: -8px;
    }
  }
}

@media (min-width: 1024px) {
  .nav-link {
    &::before {
      @include underline;
      height: 3px;
      transform: scaleY(0);
      transition: transform 0.3s;
    }
    &:hover::before {
      transform: scaleY(1);
    }
  }
}

@media screen and (max-width: 1024px) {
  .active {
    &:before {
      border-bottom: none !important;
    }
  }

  .showcase {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../assets/img/hero/hero-blue.png");
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
    box-shadow: 5px 5px 24px #0a0a0a54;
  }
}
</style>
