<template>
  <footer class="bg-ternary">
    <div class="container mx-auto text-white">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-4">
        <div class="p-4">
          <h1 class="text-2xl">
            About Us
          </h1>
          <p class="text-gray-400 text-sm leading-loose">
            We believe that code gives freedom.
            We take pride in our work and are always looking for ways to improve our process and deliver even better products to our clients.
          </p>
        </div>
        <div class="p-4 text-left">
          <h1 class="text-2xl ml-0 lg:ml-20">Links</h1>
          <ul class="ml-0 lg:ml-20 space-y-4">
            <li><a href="#showcase" v-smooth-scroll>Home</a></li>
            <li><a href="#bitcoin" v-smooth-scroll="{ offset: 0 }">Bitcoin services</a></li>
            <li><a href="/bitcoin.pdf">Bitcoin White Paper</a></li>
          </ul>
        </div>
        <div class="p-4">
          <h1 class="text-2xl">Contact</h1>
          <ul class="space-y-4">
            <li>
              <a href="mailto:info@meliorem.tech"> <i class="fas fa-envelope"></i> info@meliorem.tech</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="border-t border-gray-600 text-center py-4 text-gray-500">
        <small>All rights reserved by: Meliorem Tech</small>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer"
};
</script>

<style lang="scss" scoped>
h1 {
  @apply my-4 text-gray-300 uppercase tracking-widest text-base inline-block border-b pb-2;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    right: 50%;
    left:0;
    bottom: -2px;
    width: 50%;
    height: 2px;
    background: #378FFF;
  }
}


ul {
  li {
    @apply text-sm my-2 text-gray-400;
    a {
      @apply hover:text-primary pb-2 relative;
    }
  }
}

@media (max-width: 640px) {
  h1 {
    &:before {
      width: 50%;
      margin: 0 auto 0 0;
    }
  }
}

@mixin underline {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  border-bottom: 2px solid #378FFF;
}

@media (min-width: 1024px) {
  ul li a {
    &::before {
      @include underline;
      height: 100%;
      transform: scaleY(0);
      transition: transform 0.3s;
    }
    &:hover::before {
      transform: scaleY(1);
    }
  }
}
</style>
