import Vue from "vue";
import App from "./App.vue";
import vueSmoothScroll from "vue2-smooth-scroll";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: {
    id: "G-D02RLNW2VR",
    params: {
      send_page_view: true
    }
  }
});
/* VueTypedJS */
import VueTypedJs from "vue-typed-js";
Vue.use(VueTypedJs);

/* AOS */
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

Vue.config.productionTip = false;

// CSS
import "@/assets/css/app.css";
import "@/assets/css/style.css";
import "@fortawesome/vue-fontawesome";
import "@fortawesome/fontawesome-svg-core";
require("vue2-animate/dist/vue2-animate.min.css");

// Tilt
import VueTilt from "vue-tilt.js";
Vue.use(VueTilt);

// import "@fortawesome/fontawesome-free/css/all.css";
// import "@fortawesome/fontawesome-free/js/all.js";

// App
Vue.use(vueSmoothScroll);

new Vue({
  render: h => h(App)
}).$mount("#app");
