<template>
  <div>
    <infinite-slide-bar class="bg-gray-100 py-6 overflow-hidden" duration="30s">
      <div class="flex justify-around">
        <div class="focus:outline-none" v-for="slide in slides" :key="slide.id">
          <img :src="slide.img" class="h-8 px-4" :alt="slide.alt" />
        </div>
      </div>
    </infinite-slide-bar>
  </div>
</template>

<script>
import InfiniteSlideBar from "vue-infinite-slide-bar";

export default {
  name: "Power",
  components: {
    InfiniteSlideBar
  },
  data() {
    return {
      slides: [
        {
          id: 1,
          img: require("../assets/img/knowledge/knockout.svg"),
          alt: "Knockout Icon"
        },
        {
          id: 2,
          img: require("../assets/img/knowledge/django.png"),
          alt: "Django Icon"
        },
        {
          id: 3,
          img: require("../assets/img/knowledge/postgresql.png"),
          alt: "Postgres Icon"
        },
        {
          id: 4,
          img: require("../assets/img/knowledge/github.png"),
          alt: "GitHub Icon"
        },
        {
          id: 5,
          img: require("../assets/img/knowledge/javascript.png"),
          alt: "JavaScript icon"
        },
        {
          id: 6,
          img: require("../assets/img/knowledge/python.png"),
          alt: "Python Icon"
        },
        {
          id: 7,
          img: require("../assets/img/knowledge/angular-icon.svg"),
          alt: "Angular Icon"
        },
        {
          id: 8,
          img: require("../assets/img/knowledge/sql.png"),
          alt: "SQL Icon"
        },
        {
          id: 9,
          img: require("../assets/img/knowledge/vuejs.png"),
          alt: "VueJS Icon"
        },
        {
          id: 10,
          img: require("../assets/img/knowledge/flask.png"),
          alt: "Flask Icon"
        },
        {
          id: 11,
          img: require("../assets/img/knowledge/react.png"),
          alt: "React Icon"
        },
        {
          id: 12,
          img: require("../assets/img/knowledge/tensorflow.png"),
          alt: "Tensorflow Icon"
        },
        {
          id: 13,
          img: require("../assets/img/knowledge/pandas.png"),
          alt: "Pandas Icon"
        },
        {
          id: 14,
          img: require("../assets/img/knowledge/php.png"),
          alt: "PHP Icon"
        },
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
img {
  filter: grayscale(100%);
}
</style>
