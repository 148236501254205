<template>
  <div class="container p-10 mx-auto mt-0 rounded-xl flex items-center" id="webdev">
    <div class="">
      <div class="p-4 lg:w-2/4 text-center mx-auto mb-16" data-aos="fade-bottom" data-aos-duration="1000">
        <h6 class="uppercase text-primary font-bold text-2xl lg:text-3xl mb-4">Web Development Solutions</h6>
        <p class="text-base mt-3">
          You need it? We build it! No project is too big or too small for us. We take on any challenge and deliver a solution that meets your needs and exceeds your expectations.
        </p>
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-16">
        <div class="item relative p-12 rounded-md bg-gray-100" v-for="service in services" :key="service.id" data-aos="fade-up" data-aos-duration="1000">
          <div class="absolute top-0 left-2/4 icon w-12 h-12 bg-primary flex justify-center items-center rounded-md">
            <img :src="service.icon" class="d3" alt="Icon" />
          </div>
          <h3 class="d3 text-center font-bold my-3">{{ service.title }}</h3>
          <p class="d3 text-sm lg:text-base text-gray-800">{{ service.descr }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeployApp",
  data() {
    return {
      services: [
        {
          id: 1,
          title: "Push to Deploy",
          icon: require("../assets/img/deploy/download-icon.svg"),
          descr: "No hassle of setting up a deployment process or managing server configurations."
        },
        {
          id: 2,
          title: "SSL Certificates",
          icon: require("../assets/img/deploy/ssl-icon.svg"),
          descr: "Make your website secure with our easy to use SSL Certificates service."
        },
        {
          id: 3,
          title: "Simple Queues",
          icon: require("../assets/img/deploy/queue-icon.svg"),
          descr: "Simple, fast, reliable and affordable way to manage your data and message queues."
        },
        {
          id: 4,
          title: "Advanced Security",
          icon: require("../assets/img/deploy/security-icon.svg"),
          descr: "We keep your data safe with our advanced security measures."
        },
        {
          id: 5,
          title: "Powerful API",
          icon: require("../assets/img/deploy/settings-icon.svg"),
          descr: "Our powerful API allows you to easily integrate our services into your own applications."
        },
        {
          id: 6,
          title: "Database Backups",
          icon: require("../assets/img/deploy/db-icon.svg"),
          descr: "Your information is safe and secure with our reliable database backups service."
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.item {
  transform-style: preserve-3d;
}

.icon {
  transform: translate(-50%, -50%);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.d3 {
  transform: translateZ(2rem);
}
</style>
