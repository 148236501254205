<template>
  <section class="lg:my-20 min-h-screen" id="bitcoin">
    <div class="w-3/4 text-center mx-auto mb-10" data-aos="fade-up">
      <h6 class="uppercase text-primary font-bold text-2xl lg:text-4xl mb-6">
        Bitcoin Development Services
      </h6>
      <p class="text-sm lg:text-lg mt-3 leading-loose">
        We believe that Bitcoin is the future of money and that it will one day replace fiat currency. Therefore,  <br />
        we want to be at the forefront of this new economy and offer our clients the best possible service when it comes to Bitcoin.
      </p>
    </div>
    <VueSlickCarousel class="slide relative border-0 outline-none my-5" v-bind="settings">
      <div
        v-for="slide in slides"
        :key="slide.id"
        :class="'slide-' + slide.id"
        class="relative bg-no-repeat bg-cover m-0 border-none outline-none"
        data-aos="fade-left"
        data-aos-duration="1000"
      >
        <div class="container mx-auto ">
          <div class="card absolute p-4 top-1/4 font-montserrat sm:ml-14 sm:w-2/4">
            <div class="card__title mb-4">
              <h1 class="text-white text-4xl font-extrabold leading-48 md:text-4rem md:leading-76" :class="{ 'text-gray-50': slide.id === 3 }">
                {{ slide.title }}
              </h1>
            </div>
            <div class="card__content text-white leading-23 md:leading-30">
              <p class="font-normal text-sm md:text-lg" :class="{ 'text-gray-50': slide.id === 3 }">
                {{ slide.paragraph }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "animate.css";

export default {
  name: "CryptoSlider",
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        infinite: true,
        arrows: false,
        dots: true,
        speed: 400,
        autoplaySpeed: 3000,
        accessibility: true,
        autoplay: true
      },
      slides: [
        {
          id: 1,
          title: "Bitcoin & Liquid network",
          paragraph: "We help you create and issue your own assets on the Liquid network."
        },
        {
          id: 2,
          title: "Lightning Network",
          paragraph: "If you're looking for help with developing on the Bitcoin Lightning Network, you've come to the right place. We can help you get started and make the most of this exciting new technology."
        },
        {
          id: 3,
          title: "Bitcoin Wallets",
          paragraph: "We help you choose the right wallet to self custody your coins, or build a custom wallet that fits your needs."
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
[class*="slide-"] {
  height: 50vh;
  background-size: cover;
  background-position: center;
}

.slide-1 {
  background-image: url("../assets/img/slider/bitcoin.jpg");
  background-position: 55% center;
}

.slide-2 {
  background-image: url("../assets/img/slider/bitcoin-zoom.jpg");
}

.slide-3 {
  background-image: url("../assets/img/slider/bitcoin-lightning.jpg");
}

@media screen and (max-width: 992px) {
  .slide-1 {
    background-image: linear-gradient(to left bottom, rgba(#378fff, 0.6), rgba(#378fff, 0.7)), url("../assets/img/slider/bitcoin.jpg");
    background-position: 55% center;
  }

  .slide-2 {
    background-image: linear-gradient(to left bottom, rgba(#378fff, 0.6), rgba(#378fff, 0.7)), url("../assets/img/slider/bitcoin-zoom.jpg");
  }

  .slide-3 {
    background-image: linear-gradient(to left bottom, rgba(#378fff, 0.6), rgba(#378fff, 0.7)), url("../assets/img/slider/bitcoin-lightning.jpg");
    h1,
    p {
      color: #f3f3f3 !important;
    }
  }

  ::v-deep .slick-dots {
    bottom: 1.5625rem;
  }

  ::v-deep .slick-dots li {
    margin: 0 .125rem;
  }

  ::v-deep .slick-dots li button:before {
    font-size: 1rem;
    color: #f3f3f3;
  }
}

/********************************

Responsive Design

*********************************/

@media screen and (min-width: 992px) {
  .showcase,
  [class*="slide-"] {
    height: calc(80vh - 97px) !important;
    .card {
      width: 27.75rem;
    }
  }

  .slide-1 {
    background-size: cover;
    .card {
      top: 25%;
      &__title {
        animation: animateLeft 1.5s ease-out 0.5s;
        animation-fill-mode: backwards;
      }
      &__content {
        animation: animateRight 1.5s ease-out 0.5s;
        animation-fill-mode: backwards;
      }
    }
  }

  ::v-deep .slick-dots {
    transform: translateY(20%);
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 5;
    li {
      button {
        display: none;
      }
      height: 140px;
      width: 140px;
      background-size: 100%;
      background-repeat: no-repeat;
      margin: 0 0.25rem;
      transition: left 0.3s;
      filter: grayscale(79%) drop-shadow(1px 1px 1px #888) contrast(65%);
      background-size: cover;
      background-position: center center;
      border-radius: 0.5rem;
      box-shadow: 0 3px 0px rgb(0 0 0 / 16%), 0 3px 0px rgb(0 0 0 / 23%);
      &:nth-child(1) {
        background-image: url("../assets/img/slider/bitcoin.jpg");
      }
      &:nth-child(2) {
        background-image: url("../assets/img/slider/bitcoin-zoom.jpg");
        // transform: scale(1.3);
      }
      &:nth-child(3) {
        background-image: url("../assets/img/slider/bitcoin-lightning.jpg");
      }
      &.slick-active {
        border-radius: 0.5rem;
        box-shadow: 0 4px 0px #378fff, 0 4px 0px #378fff;
        filter: none;
      }
    }
  }
}

@media screen and (min-width: 376px) and (max-width: 639px) {
  .slide-1 {
    .card {
      width: 75%;
    }
  }
}

@media (min-width: 1824px) {
  .slide-1 {
    background-size: cover;
    .card {
      top: 32%;
    }
  }
}

/* Ipad Pro (Portrait) */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .slide-1 {
    background-size: cover;
    background-position: 73% 100%;
    .card {
      top: 35%;
    }
  }
}

/* Ipad Pro (landscape) */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .slide-1 {
    background-size: cover;
    .card {
      top: 30%;
    }
  }
}

/* iPad (landscape) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .slide-1 {
    background-size: cover;
    .card {
      &__title {
        h1 {
          font-size: 3rem !important;
          line-height: 1.3;
        }
      }
      &__content {
        .btn-cta {
          padding: 0.5rem 1.75rem;
        }
      }
    }
  }
}

/* iPad (portrait)  */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .showcase,
  [class*="slide-"] {
    height: calc(100vh - 200px) !important;
  }
  .slide-1 {
    .card {
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
