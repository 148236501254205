<template>
  <section class="-mt-10 lg:mt-28" id="technologies">
    <div class="container p-8 lg:p-4 mx-auto">
      <div class="w-3/4 text-center mx-auto mb-10">
        <h1 class="title uppercase text-primary font-bold text-2xl lg:text-6xl mb-4" data-aos="fade-up">
          Other Services
        </h1>
        <p class="text-sm mt-3" data-aos="fade-up">
          We provide the best in class technology solutions for your business. Stay ahead of the curve with us! <br />
          We help you keep up with the latest trends in technology.
        </p>
      </div>
      <div
        class="service flex flex-col sm:flex-row py-4 md:mb-24 lg:px-8"
        v-for="service in services"
        :key="service.id"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-duration="1200"
      >
        <div class="img flex items-center flex-col justify-center flex-1 px-5 mb-8 md:mb-0">
          <!-- :data-aos="{ 'fade-right': service.id % 2 == 0 }" -->
          <img :src="service.img" class="w-2/4 lg:w-8/12" alt="Image" :class="{ 'w-3/4': service.id === 3 }" v-tilt />
        </div>
        <div class="body flex flex-col justify-center flex-1 leading-loose">
          <h1 class="text-3xl md:text-4xl text-center md:text-left mb-6 font-bold text-gray-700">{{ service.title }}</h1>
          <div class="text-center md:text-left">
            <p class="mb-3">{{ service.description }}</p>
            <ul v-if="service.bullets" class="crypto-ul text-left">
              <li v-for="service in service.bullets" :key="service">{{ service }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "OtherServices",
  data() {
    return {
      services: [
        {
          id: 1,
          img: require("../assets/img/business-software.svg"),
          img_alt: "Business Software Image",
          title: "Business Software",
          description: "We draft software specification, advise clients on the development approach and kickstart any project",
          aos: "fade-right",
          img_aos_anime: "zoom-in",
          descr_aos_anime: "fade-right"
        },
        {
          id: 2,
          img: require("../assets/img/bi-software.svg"),
          img_alt: "Business Intelligence Image",
          title: "Business Intelligence Software",
          description: "We cover our clients needs with full-cycle advanced analytics, data consulting, reporting and Dashboarding.",
          aos: "fade-left",
          img_aos_anime: "fade-right",
          descr_aos_anime: "fade-left"
        },
        {
          id: 3,
          img: require("../assets/img/ai-and-ml.svg"),
          img_alt: "AI & ML Image",
          title: "Artificial Intelligence & Machine Learning",
          description: "We use advanced Artificial Intelligence (AI) and Machine Learning Solutions to help you reshape the future of your business.",
          aos: "fade-right",
          img_aos_anime: "fade-left",
          descr_aos_anime: "fade-right"
        },
        // {
        //   id: 4,
        //   img: require("../assets/img/blockchain-app-dev.svg"),
        //   img_alt: "Blockchain Image",
        //   title: "Blockchain & App Development & Integration",
        //   description:
        //     "We help you build an entire decentralised ecosystem, we offer custom blockchain solutions that allow your business to communicate, thrive and innovate within. We specialise in multiple aspects of blockchain development such as blockchain maintenance, cryptocurrency wallets, smart contracts, exchanges and much more.",
        //   aos: "fade-left",
        //   img_aos_anime: "fade-right",
        //   descr_aos_anime: "fade-left"
        // },
        // {
        //   id: 5,
        //   img: require("../assets/img/defi-solution.svg"),
        //   img_alt: "DeFi Solutions Development Image",
        //   title: "DeFi Solutions Development",
        //   description:
        //     "We offer quality DeFi development solutions that will efficiently onboard you on the billion-dollar revolutionary Decentralised Finance market.",
        //   aos: "fade-right",
        //   img_aos_anime: "fade-left",
        //   descr_aos_anime: "fade-right"
        // },
        // {
        //   id: 6,
        //   img: require("../assets/img/cryptocurrency-dev.svg"),
        //   img_alt: "Cryptocurrency Development Services Image",
        //   title: "Cryptocurrency Development Services",
        //   description: `Our Cryptocurrency services include:`,
        //   bullets: [
        //     "Cryptocurrency Coin / Token Creation",
        //     "Cryptocurrency Wallet development",
        //     "Cryptocurrency Exchange Development",
        //     "BlockChain development",
        //     "Smart Contracts Development & Audit"
        //   ],
        //   aos: "fade-left",
        //   img_aos_anime: "fade-right",
        //   descr_aos_anime: "fade-left"
        // },
        {
          id: 4,
          img: require("../assets/img/multilangual.svg"),
          img_alt: "Multilingual Image",
          title: "Multilingual",
          description: `Service in your language, we speak Spanish, English, Swedish, German, & French.`,
          aos: "fade-right",
          img_aos_anime: "fade-left",
          descr_aos_anime: "fade-right"
        }
      ]
    };
  }
};
</script>

<style scoped lang="scss">
.title {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 80%;
    transform: translate(-50%, -50%);
    width: 250px;
    height: 250px;
    opacity: 0.8;
    z-index: -1;
    background-image: url("../assets/img/hero/pattern.svg");
  }
}

@media screen and (max-width: 768px){
  .title {
    &:before {
      width: 150px;
      height: 150px;
    }
  }
}

.container {
  max-width: 1176px;
}

.crypto-ul {
  list-style: none;
  margin-left: 1rem;
  li {
    &:before {
      content: "\2022";
      color: #378fff;
      font-weight: bold;
      display: inline-block;
      width: 1rem;
      margin-left: -1rem;
    }
  }
}

#services {
  .service {
    margin-bottom: 4.9375rem;
  }
  .service:nth-child(even) {
    flex-direction: row-reverse;
  }
}

@media screen and (max-width: 992px) {
  #services {
    .service:nth-child(even) {
      flex-direction: column;
    }
  }
}
</style>
