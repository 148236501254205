<template>
  <div>
    <header>
      <Navbar />
    </header>
    <main>
      <Hero />
      <div id="services">
        <WebDevSection />
        <CryptoSlider />
        <OtherServices />
      </div>
      <Power />
    </main>
    <footer>
      <Footer />
    </footer>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Hero from "@/components/Hero.vue";
import WebDevSection from "@/components/WebDevSection.vue";
import CryptoSlider from "@/components/BitcoinSlider.vue";
import OtherServices from "@/components/OtherServices.vue";
import Power from "@/components/Power.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "app",
  components: {
    Navbar,
    Hero,
    WebDevSection,
    CryptoSlider,
    OtherServices,
    Power,
    Footer
  },
  Footerdata() {
    return {};
  },
  created() {},
  beforeDestroy() {},
  mounted() {},
  computed: {},
  methods: {}
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500&display=swap");

body {
  position: relative;
  font-family: "Montserrat", "Open Sans", "Helvetica Neue", sans-serif !important;
}

</style>
